
import { ref, reactive, watch, computed } from 'vue';

import { useI18n } from 'vue-i18n';
import { useToast } from 'primevue/usetoast';

import InputNumber from 'primevue/inputnumber';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import Button from 'primevue/button';
import { useVuelidate } from '@vuelidate/core';
import { email, required, minLength } from '@vuelidate/validators';

import { Icon, Loader } from '../../../components/ui';
import { useStores } from '@/store/Stores';

export default {
  components: {
    InputNumber,
    InputText,
    Checkbox,
    Button,
    Icon,
    Loader
  },
  setup() {
    const { companyStore, companyStoreState } = useStores();

    const { t } = useI18n();
    const toast = useToast();

    const isChangePassword = ref(false);
    const data = computed(() => companyStoreState.data);

    const rules = reactive({
      title: { required },
      email: { required, email },
      name: { required },
      phone: { required },
      address: { required },
      credits: {},
      additional_uploads: {},
      tokens: {},
      is_active: {},
      premoderation: {}
    });

    const v$ = useVuelidate(rules, data);

    const handleSubmit = (isFormValid) => {
      v$.value.$touch();
      if (!isFormValid) {
        return;
      }

      companyStore.saveChanges(data.value, toast, t);
    };

    const toggleBlock = () => {
      companyStore.toggleBlock();
    };

    const addCredits = () => {
      companyStore.addCredits(50);
    };

    const addTokens = () => {
      const tokensToAdd = prompt('How much tokens to you want to add?', '10');

      if (tokensToAdd && +tokensToAdd) {
        companyStore.addTokens(+tokensToAdd, toast, t);
      }
    };

    const toggleSelectAngle = (angle) => {
      angle.isSelected = !angle.isSelected;
    };

    return {
      v$,
      state: companyStoreState,
      isChangePassword,
      addCredits,
      addTokens,
      handleSubmit,
      toggleBlock,
      toggleSelectAngle
    };
  }
};
